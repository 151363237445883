<template>
  <nav class="navbar navbar-expand-sm navbar-dark bg-primary nav-custom mb-4">
    <div class="container-fluid">
      <a class="navbar-brand" href="#"><img src="/logo_white.png" height="30"></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <router-link class="nav-link" :class="{ 'active': $route.name=='home' }" to="/">Browser</router-link>
          </li>
        </ul>
        <ul class="navbar-nav d-flex">
          <li class="nav-item">
            <router-link class="nav-link" :class="{ 'active': $route.name=='settings' }" to="/settings">Settings</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<style scoped lang="scss">
  .nav-custom {
    background: #0054A6 !important;
  }

  nav .nav-item:hover {
    background: #3af !important;
    border-radius: 4px;
  }

  nav .active:not(:hover) {
    background: #3076cb;
    border-radius: 4px;
  }
</style>
