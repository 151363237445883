import { createStore } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'

export default createStore({
  state: {
    initialized: false,

    settings: {},
  },

  mutations: {
    updateField,
  },

  getters: {
    getField,
  },

  actions: {
  },

  modules: {
  }
})
