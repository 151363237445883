<template>
  <select class="form-select" multiple @change="handleChange">
    <option v-for="item in model" :key="item" :style="item.style" :title="item.title" @dblclick="handleDoubleclick(item)">{{item.text}}</option>
  </select>
</template>

<script>
  export default {
    props: [ 'modelValue' ],

    data: function() {
      return {
        model: undefined,
      }
    },

    methods: {
      handleDoubleclick: function(item) {
        this.$emit('dblclick', item)
      },

      handleChange: function(e) {
        for ( let i=0; i<e.target.options.length; i++ ) {
          this.model[i].selected = e.target.options[i].selected
        }
        this.$emit('update:modelValue', this.model)
      },
    },

    watch: {
      modelValue: {
        handler: function() {
          this.model = this.modelValue
        },
        immediate: true,
        deep: true,
      }
    },
  }
</script>
