<template>
  <div class="cover-object d-flex justify-content-center">
    <div class="spinner-border spinner-custom text-success" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
	.cover-object {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 9999
	}
	.spinner-custom {
		width: 4em;
		height: 4em;
	}
</style>
