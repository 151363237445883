<template>
  <div class="modal fade" ref="thisDialog" :tabindex="model.onCancel || model.cancel ? '-1' : ''" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header" :class="model.type=='warning' ? 'bg-warning text-dark' : (model.type=='danger' ? 'bg-danger text-white' : 'bg-primary text-white')">
          <h5 class="modal-title" v-html="model.title"></h5>
          <button v-if="model.onCancel || model.cancel" type="button" :class="model.type=='warning' ? 'btn-close' : 'btn-close btn-close-white'" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center" v-html="model.body"/>
        <div class="modal-footer" v-if="model.button || model.onButton || model.cancel || model.onCancel">
          <button v-if="model.cancel" type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="() => { model.onCancel && model.onCancel() }">{{model.cancel}}</button>
          <button v-if="model.button || model.onButton" type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="() => { model.onButton && model.onButton() }">{{model.button}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [ 'modelValue' ],

    data: function() {
      return {
        dialog: null,
        model: {},
      }
    },

    mounted: function() {
      this.dialog = new bootstrap.Modal(this.$refs.thisDialog, {})

      this.$refs.thisDialog.addEventListener('hidden.bs.modal', () => {
        this.model.show = false
        this.$emit('update:modelValue', this.model)
        if ( this.model.onClose ) {
          this.model.onClose()
        }
      }, false)
    },

    watch: {
      modelValue: {
        handler: function() {
          if ( this.dialog && this.model ) {
            this.model = this.modelValue
            if ( this.model.show ) {
              this.dialog.show()
            } else {
              this.dialog.hide()
            }
          }
        },
        immediate: true,
        deep: true,
      }
    },
  }
</script>
