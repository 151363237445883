<template>
  <TheDialog v-model="dialog" />
  <ErrorDialog v-model="errorDialog" />

    <div class="container" :class="{ 'panel-busy' : busy }">

    <spinner v-if="busy" />

    <div class="row">
      <div class="col">

        <div class="card">
          <div class="card-header bg-secondary text-white pb-0">
            <h3>Settings</h3>
          </div>
          <div class="card-body">

            <div class="container">
              <div class="row py-2">
                <div class="col">
                  <label class="form-label">Config
                    <div v-if="configError" class="text-danger d-inline">{{configError}}</div>
                  </label>
                  <textarea rows="10" class="form-control" v-model="settingsText"></textarea>                  
                </div>
              </div>
              <div class="row py-2">
                <div class="col">
                  <button class="btn btn-primary" @click="save()" :disabled="saveDisabled">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapFields } from 'vuex-map-fields'
  import jslib from '@mexipassit/jslib'
  import lib from '../lib.js'
  import Spinner from '@/components/Spinner'
  import TheDialog from '@/components/TheDialog'
  import ErrorDialog from '@/components/TheDialog'
  import aws from 'aws-sdk'

  export default {
    components: {
      Spinner,
      TheDialog,
      ErrorDialog,
    },

    data: function() {
      return {
        busy: 0,

        dialog: {},
        errorDialog: {},

        settingsText: '',
      }
    },

    mounted: function() {
      this.startup()
    },

    methods: {
      startup: async function() {
        if ( !this.initialized ) {
          this.settings = lib.getSettings()
          this.initialized = true
        }
        let settings = jslib.copyObject(this.settings)
        settings.s3key = settings.s3keySecret = ''
        this.settingsText = JSON.stringify(settings, null, 2)
      },

      save: async function() {
        let settings = jslib.JSONParse(jslib.b64DecodeOrDefault(this.settingsText))
        let s3 = new aws.S3({ accessKeyId: settings.s3key, secretAccessKey: settings.s3keySecret })
        try {
          this.busy++
          await Promise.all(settings.buckets.map(item => lib.s3getObjects(s3, item.name, lib.delimiter, '', null) ))
          this.settings = settings
          lib.setSettings(this.settings)
          this.dialog = { show: true, title: 'Information', body: 'The config has been saved for future use', button: 'OK', onCancel: () => {}, onClose: () => {
            let settings = jslib.JSONParse(this.settingsText)
            settings.s3key = settings.s3keySecret = ''
            this.settingsText = JSON.stringify(settings, null, 2)
          } }
        } catch (ex) {
          this.errorDialog = { show: true, type: 'danger', title: 'Error', body: ex.message, onCancel: () => {}, button: 'Ok', onButton: () => { } }
        } finally {
          this.busy--
        }
      },
    },

    computed: {
      ...mapFields([ 'initialized', 'settings' ]),

      configError: function() {
        if ( this.settingsText ) {
          if ( !jslib.JSONParse(jslib.b64DecodeOrDefault(this.settingsText)) ) {
            return 'Bad JSON format'
          } else if ( lib.settingsError(this.settingsText) ) {
            return lib.settingsError(this.settingsText)
          } else {
            return null
          }
        } else {
          return 'Empty'
        }
      },

      saveDisabled: function() {
        return lib.settingsError(this.settingsText) || this.busy
      }
    }
  }
</script>
