<template>
  <div class="modal fade" ref="thisDialog" :tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white">
          <h5 class="modal-title">New folder</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center">
          <label class="form-label">Folder name <div class="d-inline text-warning" v-if="name && folderError">({{folderError}})</div></label>
          <input type="text" class="form-control" v-model="name" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" :disabled="folderError" @click="() => { model.onButton && model.onButton(this.name) }">Ok</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [ 'modelValue' ],

    data: function() {
      return {
        dialog: null,
        model: {},
        name: '',
      }
    },

    mounted: function() {
      this.dialog = new bootstrap.Modal(this.$refs.thisDialog, {})

      this.$refs.thisDialog.addEventListener('hidden.bs.modal', () => {
        this.model.show = false
        this.$emit('update:modelValue', this.model)
        this.name = ''
      }, false)
    },

    watch: {
      modelValue: {
        handler: function() {
          if ( this.dialog && this.model ) {
            this.model = this.modelValue
            if ( this.model.show ) {
              this.dialog.show()
            } else {
              this.dialog.hide()
            }
          }
        },
        immediate: true,
        deep: true,
      },
    },

    computed: {
      folderError: function() {
        if ( !this.name.trim() ) {
          return 'the folder can not be blank'
        } else if ( this.name.indexOf('/')!=-1 || this.name.indexOf('\\')!=-1 ) {
          return 'Invalid characters for a folder name'
        } else {
          return null
        }
      }
    }
  }
</script>
