import jslib from '@mexipassit/jslib'

export default {
  delimiter: '/',

  getSettings: function() {
    let settings = {}
    try {
      settings = JSON.parse(atob(localStorage.getItem('settings')))
      if ( typeof settings!='object' || Array.isArray(settings) ) {
        settings = {}
      }
    } catch {
      settings = {}
    }
    settings.s3key = typeof settings.s3key=='string' ? settings.s3key.trim() : ''
    settings.s3keySecret = typeof settings.s3keySecret=='string' ? settings.s3keySecret.trim() : ''
    settings.email = typeof settings.email=='string' ? settings.email.trim() : '' 
    settings.buckets = typeof settings.buckets=='object' && Array.isArray(settings.buckets) ? settings.buckets : [
      {
        name: ''
      }
    ]
    return settings
  },

  setSettings: function(settings) {
    if ( settings != typeof(settings)=='object' || Array.isArray(settings) ) {
      settings = {}
    }
    localStorage.setItem('settings', btoa(JSON.stringify(settings)))
  },

  settingsError: function(settings) {
    if ( typeof settings=='string' ) {
      settings = jslib.JSONParse(jslib.b64DecodeOrDefault(settings))
    }
    if ( typeof settings=='object' && !Array.isArray(settings) ) {
      if ( !settings.s3key?.trim() || !settings.s3keySecret?.trim() ) {
        return 'Incomplete AWS/S3 credentials'
      } else if ( typeof settings.buckets!='object' || !Array.isArray(settings.buckets) ) {
        return 'Missing at least 1 bucket'
      } else if ( !settings.email?.trim() || !new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/).test(settings.email) ) {
        return 'Bad email'
      } else if ( settings.buckets.find(item => !item.name) ) {
        return 'Bad buckets, they all need a name'
      } else {
        return null
      }
    } else {
      return 'config not an object'
    }
  },

  s3deleteObjects: async function(s3, bucket, keys) {
    return new Promise((resolve, reject) => {

      s3.deleteObjects({ Bucket: bucket, Delete: { Objects: keys.map(item => ({ Key: item })) } }, (error, data) => {
        if ( error ) {
          reject(error)
        } else {
          resolve(data)
        }
      })

    })
  },

  s3upload: async function(s3, key, file, bucket, acl, tag, progress) {
    return new Promise((resolve, reject) => {

      s3.upload({
        Key: key,
        Body: file,
        Bucket: bucket,
        ACL: acl,
        Tagging: tag,
      }, (error, data) => {
        if ( error ) {
          reject(error)
        } else {
          resolve(data)
        }
      }).on('httpUploadProgress', (progressData) => {
        if ( typeof progress=='function' ) {
          progress(progressData)
        }
      })

    })
  },

  s3getObjects: async function(s3, bucket, delimiter, prefix, nextContinuationToken) {
    return new Promise((resolve, reject) => {

      s3.listObjectsV2({ Bucket: bucket, Delimiter: delimiter, Prefix: prefix, ContinuationToken: nextContinuationToken }, (error, data) => {
        if ( error ) {
          reject(error)
        } else {
          resolve(data)
        }
      })

    })
  },

  s3createFolder: async function(s3, bucket, folder) {
    return new Promise((resolve, reject) => {

      s3.putObject({ Bucket: bucket, Key: folder }, (error, data) => {
        if ( error ) {
          reject(error)
        } else {
          resolve(data)
        }
      })

    })
  },
}
