<template>

  <input type="file" ref="fileUpload" class="d-none" @change="fileChosen" multiple>

  <a ref="downloader" class="d-none" />

  <NewFolderDialog v-model="newFolderDialog" />
  <TheDialog v-model="dialog" />
  <ErrorDialog v-model="errorDialog" />
  <UploadDialog v-model="uploadDialog" />
  <DeleteDialog v-model="deleteDialog" />

  <div class="container" :class="{ 'panel-busy' : busy }">

    <spinner v-if="busy" />

    <div class="row">
      <div class="col">

        <div class="card">
          <div class="card-header bg-secondary text-white pb-0">
            <h3>Browser</h3>
          </div>
          <div class="card-body">

            <div class="container">
              <div class="row py-2" v-if="settingsError(settings)">
                <div class="col">
                  <div class="bg-warning rounded d-flex align-items-center">
                    <h2 class="d-inline m-2">Invalid s3 credentials</h2>
                    <router-link class="btn btn-sm btn-primary" to="/settings">Settings</router-link>
                  </div>
                </div>
              </div>
              <div class="row py-2" v-if="!settingsError(settings)">
                <div class="col">
                  <h3>Bucket</h3>
                  <select class="form-select" v-model="bucket" @change="changeBucket" :disabled="bucketDisabled">
                    <option v-for="b in settings.buckets" :key="b">{{b.name}}</option>
                  </select>
                </div>
              </div>
              <div class="row py-2" v-if="!settingsError(settings)">
                <div class="col">
                  <h3>Folder</h3>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" :value="folder" disabled="true" />
                    <button class="btn btn-secondary" @click="goBack" :disabled="backDisabled">Back</button>
                  </div>
                </div>
              </div>
              <div class="row py-2" v-if="!settingsError(settings)">
                <div class="col">
                  <multi-select class="file-custom" v-model="files" :disabled="selectionsDisabled" @dblclick="openOneSelection" @change="selectionChange"></multi-select>
                </div>
                <div class="col-auto">
                  <div class="card">
                    <div class="card_body">
                      <div class="container p-2">
                        <div class="row p-2">
                          <div class="col">
                            <button class="btn btn-sm btn-success action-button-custom" @click="startup" :disabled="refreshDisabled">Refresh</button>
                          </div>
                        </div>
                        <div class="row p-2">
                          <div class="col">
                            <button class="btn btn-sm btn-primary action-button-custom" @click="openOneSelection" :disabled="openDisabled">{{openText}}</button>
                          </div>
                        </div>
                        <div class="row p-2">
                          <div class="col">
                            <button class="btn btn-sm btn-primary action-button-custom" @click="$refs.fileUpload.click()" :disabled="uploadDisabled">Upload</button>
                          </div>
                        </div>
                        <div class="row p-2">
                          <div class="col">
                            <button class="btn btn-sm btn-primary action-button-custom" @click="newFolder" :disabled="newFolderDisabled">New folder</button>
                          </div>
                        </div>
                        <div class="row p-2">
                          <div class="col">
                            <button class="btn btn-sm btn-primary action-button-custom" @click="_delete" :disabled="deleteDisabled">Delete</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row py-2" v-if="uploading">
                <div class="col">
                  <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar" :style="progressStyle" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">{{progress.toFixed(1)}}%</div>
                  </div>
                </div>
              </div>
              <div class="row py-2" v-if="downloadLink">
                <div class="col">
                  <h3>Download link for public sharing</h3>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" v-model="downloadLink" disabled="true" />
                    <button class="btn btn-sm btn-primary" @click="copyToClipboad(downloadLink)">Copy to the clipboard</button>
                  </div>
                </div>
              </div>
              <div class="accordion" v-if="showTechnicalDetail">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                      Technical detail
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                    <div class="accordion-body ">
                      <div class="input-group my-1">
                        <label class="form-label me-2">Link format 1</label>
                        <input type="text" class="form-control" v-model="s3link1" disabled="true" />
                        <button class="btn btn-sm btn-primary" @click="copyToClipboad(s3link1)">Copy to the clipboard</button>
                      </div>
                      <div class="input-group my-1">
                        <label class="form-label me-2">Link format 2</label>
                        <input type="text" class="form-control" v-model="s3link2" disabled="true" />
                        <button class="btn btn-sm btn-primary" @click="copyToClipboad(s3link2)">Copy to the clipboard</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .file-custom {
    height: 300px;
  }

  .action-button-custom {
    width: 130px;
  }
</style>

<script>
  import { mapFields } from 'vuex-map-fields'
  import jslib from '@mexipassit/jslib'
  import lib from '../lib.js'
  import Spinner from '@/components/Spinner'
  import TheDialog from '@/components/TheDialog'
  import ErrorDialog from '@/components/TheDialog'
  import MultiSelect from '@/components/MultiSelect'
  import aws from 'aws-sdk'
  import NewFolderDialog from '@/components/NewFolderDialog'
  import UploadDialog from '@/components/UploadDialog'
  import DeleteDialog from '@/components/DeleteDialog'
  import moment from 'moment'

  export default {
    components: {
      Spinner,
      TheDialog,
      ErrorDialog,
      NewFolderDialog,
      UploadDialog,
      DeleteDialog,
      MultiSelect,
    },

    data: function() {
      return {
        busy: 0,

        dialog: {},
        errorDialog: {},
        uploadDialog: {},
        deleteDialog: {},

        bucket: '',
        files: [],
        prefix: '',
        back: '..',
        s3: null,
        progress: 0,
        uploading: false,
        downloadLink: '',
        s3link1: '',
        s3link2: '',
        newFolderDialog: {},
      }
    },

    mounted: function() {
      this.startup()
    },

    watch: {
      settings: {
        handler: function() {
          if ( !this.settingsError(this.settings) && this.settings.buckets.length>0 ) {
            this.bucket = this.settings.buckets[0].name
          }
        },
        deep: true,
        immediate: true,
      }
    },

    methods: {
      startup: async function() {
        if ( !this.initialized ) {
          this.settings = lib.getSettings()
          this.initialized = true
        }

        if ( !this.settingsError(this.settings) ) {

          this.bucket = this.bucket || this.settings.buckets[0].name

          this.s3 = new aws.S3({ accessKeyId: this.settings.s3key, secretAccessKey: this.settings.s3keySecret })

          try {
            this.files = await this.getFolder(this.s3, this.bucket, lib.delimiter, this.prefix)
          } catch (ex) {
            this.errorDialog = { show: true, info: 'danger', title: 'Error', body: ex.message, onCancel: () => {}, button: 'Ok', onButton: () => { } }
          }
        }
      },

      getFolder: async function(s3, bucket, delimiter, prefix) {
        try {
          this.busy++
          let data = {}
          let files = []

          do {
            data = await lib.s3getObjects(s3, bucket, delimiter, prefix, data.NextContinuationToken)
            files = files.concat(
              data.CommonPrefixes.map(item => ({ dir: true, name: item.Prefix }))
                .concat(data.Contents
                  .filter(item => item.Key!=prefix)
                    .map(item => ({ dir: false, name: item.Key, size: item.Size, lastModified: item.LastModified }))
                )
            )
          } while ( data.IsTruncated )
          files = files.filter(item => item.dir).concat(files.filter(item => !item.dir))

          files.forEach(item => {
            item.style = this.fileStyle(item)
            item.text = this.fileText(item)
            item.title = this.itemTitle(item)
          })

          return files
        } finally {
          this.busy--
        }
      },

      _delete: async function() {
        let files = this.selections.filter(item => !item.dir)
        if ( files.length>0 ) {

          this.deleteDialog = { show: true, files, cancel: 'Never mind', button: 'Ok', onButton: async () => {

            try {
              this.busy++
              let keys = this.selections.map(item => item.name)
              await lib.s3deleteObjects(this.s3, this.bucket, keys)
            } catch (ex) {
              this.errorDialog = { show: true, info: 'danger', title: 'Error', body: ex.message, onCancel: () => {}, button: 'Ok', onButton: () => { } }
            } finally {
              this.busy--
              this.startup()
            }

          } }
        } else {
          try {
            this.busy++
            let dir = this.selections[0].name
            let files = await this.getFolder(this.s3, this.bucket, lib.delimiter, dir)
            if ( files.length==0 ) {

              dir = `<div class="text-nowrap">${dir}</div>`

              this.dialog = { show: true, title: 'Warning', body: `Do you really want to delete the folder ${dir}?`, cancel: 'Never mind', button: 'Ok', onButton: async () => {

                try {
                  this.busy++
                  await lib.s3deleteObjects(this.s3, this.bucket, [this.selections[0].name])
                } catch (ex) {
                  this.errorDialog = { show: true, info: 'danger', title: 'Error', body: ex.message, onCancel: () => {}, button: 'Ok', onButton: () => { } }
                } finally {
                  this.busy--
                  this.startup()
                }

              } }

            } else {
              this.dialog = { show: true, info: 'info', title: 'Attention', body: 'Can not delete folders that are not empty', onCancel: () => {}, button: 'Ok', onButton: () => { } }
            }
          } catch (ex) {
            this.errorDialog = { show: true, info: 'danger', title: 'Error', body: ex.message, onCancel: () => {}, button: 'Ok', onButton: () => { } }
          } finally {
            this.busy--
          }
        }
      },

      newFolder: function() {
        this.newFolderDialog = { show: true, onButton: async (folder) => {

          try {
            this.busy++
            await lib.s3createFolder(this.s3, this.bucket, this.prefix + folder + lib.delimiter)
          } catch (ex) {
            this.errorDialog = { show: true, info: 'danger', title: 'Error', body: ex.message, onCancel: () => {}, button: 'Ok', onButton: () => { } }
          } finally {
            this.busy--
            this.startup()
          }

        } }
      },

      changeBucket: function() {
        this.prefix = ''
        this.startup()
      },

      coolSize: function(size) {
        if ( size < 1024 ) {
          return size + ''
        } else if ( size < 1024*1024) {
          return (size / 1024).toFixed() + ' kb'
        } else if ( size < 1024*1024*1024 ) {
          return (size / 1024*1024).toFixed() + ' mb'
        } else {
          return (size / 1024*1024*1024).toFixed() + ' gb'
        }
      },

      fileText: function(file) {
        let name = file.name
        if ( file.dir ) {
          name = this.removeFinalDelimiter(name)
        }
        let pos = name.lastIndexOf(lib.delimiter)
        if ( pos!=-1 ) {
          name = name.substr(pos+1)
        }
        return file?.dir ? name : `${name} , ${this.coolSize(file.size)} , ${moment(file.lastModified).format("YYYY-MM-DD HH:mm:ss")}`
      },

      fileStyle: function(file) {
        return file.dir ? 'color: #080;' : 'color: #008'
      },

      itemTitle: function(file) {
        return file.dir ? `folder: ${file.name}\nbucket ${this.bucket}` : `file: ${file.name}\nsize: ${file.size} b\nbucket: ${this.bucket}`
      },

      selectionChange: function() {
        let link = ''
        this.s3link1 = ''
        this.s3link2 = ''

        let sels = this.selections
        if ( sels.length==1 ) {
          let match = sels[0]
          if ( !match.dir ) {
            let region = this.settings.region || ''
            region = region ? region + '.' : ''
            this.s3link1 = `https://s3.${region}amazonaws.com/${this.bucket}/${match.name}`
            this.s3link2 = `https://${this.bucket}.s3.${region}amazonaws.com/${match.name}`
            link = jslib.config.endpoints.getDoc[jslib.config.prod].url + `?${encodeURIComponent(jslib.string2b64(JSON.stringify({
              s: this.s3link1,
              t: 'octet/stream',
              cd: 'download; filename='
            })))}`
          }
        }
        this.downloadLink = link
      },

      removeFinalDelimiter: function(s) {
        if ( s.length>0 && s[s.length-1]==lib.delimiter ) {
          s = s.substr(0, s.length-1)
        }
        return s
      },

      openOneSelection: function() {
        let sels = this.selections
        if ( sels.length==1 ) {
          this.open(sels[0])
        }
      },

      open: function(file) {
        if ( file.dir ) {
          this.prefix = file.name
          this.startup()
        } else {
          this.selectionChange()
          this.$refs.downloader.href = this.downloadLink
          this.$refs.downloader.click()
        }
      },

      goBack: function() {
        let name = this.prefix
        name = this.removeFinalDelimiter(name)
        let pos = name.lastIndexOf(lib.delimiter)
        name = name.substr(0, pos==-1 ? 0 : pos+1)
        this.prefix = name
        this.startup()
      },

      fileChosen: function() {
        if ( this.$refs.fileUpload?.files?.length>0 ) {

          let expirations = this.settings.buckets.find(item => item.name==this.bucket).expirations
          let files = []
          for ( let file of this.$refs.fileUpload.files ) {
            files.push(file.name)
          }

          this.uploadDialog = { show: true, expirations, files, onButton: async (data) => {
            try {
              let tags = [data.expiration, this.settings.email ? `email=${encodeURIComponent(this.settings.email)}` : ''].filter(item => item).join('&')
              this.uploading = true
              this.progress = 0
              let promises = []
              let progresses = {}
              let totals = 0
              for ( let file of this.$refs.fileUpload.files ) {
                totals += file.size
                promises.push(lib.s3upload(this.s3, this.prefix + file.name, file, this.bucket, 'public-read', tags, (data) => {
                  progresses[data.key] = data.loaded
                  let loaded = 0
                  Object.entries(progresses).forEach(item => loaded+=item[1])
                  this.progress = loaded / totals * 100
                }))
              }
              await Promise.all(promises)
            } catch (ex) {
              this.errorDialog = { show: true, info: 'danger', title: 'Error', body: ex.message, onCancel: () => {}, button: 'Ok', onButton: () => { } }
            } finally {
              this.uploading = false
              this.progress = 0
              this.startup()
            }

          } }
        }
      },

      copyToClipboad: async function(s) {
        navigator.clipboard.writeText(s)
      },

      settingsError: function(settings) {
        return lib.settingsError(settings)
      },
    },

    computed: {
      ...mapFields([ 'initialized', 'settings' ]),

      progressStyle: function() {
        return `width: ${this.progress}%`
      },

      openText: function() {
        let name = 'Open'
        let sels = this.selections
        if ( sels.length==1 ) {
          let match = this.files.filter(item => item.text==sels[0].text)
          if ( match.length>0 ) {
            if ( match[0].dir ) {
              name = 'Enter'
            } else {
              name = 'Download'
            }
          }
        }
        return name
      },

      folder: function() {
        return this.prefix || lib.delimiter
      },

      selectionsDisabled: function() {
        return this.busy>0 || this.uploading || this.settingsError(this.settings)
      },

      refreshDisabled: function() {
        return this.busy>0 || this.uploading || this.settingsError(this.settings)
      },

      bucketDisabled: function() {
        return this.busy>0 || this.uploading || this.settingsError(this.settings)
      },

      openDisabled: function() {
        return this.busy>0 || this.uploading|| this.settingsError(this.settings) || this.selections.length!=1
      },

      uploadDisabled: function() {
        return this.busy>0 || this.uploading || this.settingsError(this.settings)
      },

      newFolderDisabled: function() {
        return this.busy>0 || this.uploading || this.settingsError(this.settings)
      },

      deleteDisabled: function() {
        if ( this.busy>0 || this.uploading ) {
          return true
        } else {
          let sels = this.selections
          let dirs = sels.filter(item => item.dir)
          let files = sels.filter(item => !item.dir)
          if ( dirs.length>0 && files.length>0 ) {
            return true
          } else {
            if ( files.length>0 ) {
              return false
            } else {
              if ( dirs.length==1 ) {
                return false
              } else {
                return true
              }
            }
          }
        }
      },

      backDisabled: function() {
        return this.uploading || !this.prefix
      },

      showTechnicalDetail: function() {
        return this.settings?.buckets?.find(item => item.name==this.bucket).technical &&
          (this.s3link1 || this.s3link2)
      },

      selections: function() {
        return this.files.filter(item => item.selected)
      },
    }
  }
</script>
