<template>
  <div class="modal fade" ref="thisDialog" :tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white">
          <h5 class="modal-title">Upload and options</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row mb-4" v-if="expirations.length>1">
              <div class="col">
                <label class="form-label">Expiration options</label>
                <select class="form-select" v-model="expiration">
                  <option v-for="e in expirations" :key="e">{{e}}</option>
                </select>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col">
                <label class="form-label">Files that will be uploaded</label>
                <select class="form-select" v-model="files" multiple :disabled="true">
                  <option v-for="f in files" :key="f">{{f}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="() => { model.onCancel && model.onCancel() }">Cancel</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="() => { model.onButton && model.onButton({ expiration: this.expiration }) }">Upload</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [ 'modelValue' ],

    data: function() {
      return {
        dialog: null,
        model: {},
        expiration: '',
      }
    },

    mounted: function() {
      this.dialog = new bootstrap.Modal(this.$refs.thisDialog, {})

      this.$refs.thisDialog.addEventListener('hidden.bs.modal', () => {
        this.model.show = false
        this.$emit('update:modelValue', this.model)
        if ( this.model.onClose ) {
          this.model.onClose()
        }
      }, false)
    },

    watch: {
      modelValue: {
        handler: function() {
          if ( this.dialog && this.model ) {
            this.model = this.modelValue
            if ( this.model.show ) {
              this.dialog.show()
            } else {
              this.dialog.hide()
            }
            this.expiration = this.expiration || this.expirations[0]
          }
        },
        immediate: true,
        deep: true,
      }
    },

    computed: {
      expirations: function() {
        return this.model.expirations || []
      },

      files: function() {
        return this.model.files || []
      }
    },
  }
</script>
